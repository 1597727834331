<template>
  <div id="works-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div class="ts-page-content">
      <div ref="$topGroup" class="row row-cols-1 row-cols-sm-2">
        <div class="col">
          <lottie class="ts-title" :options="titleOptions" :width="500" :height="200" />
        </div>

        <div class="col d-flex align-items-end justify-content-end">
          <div class="tab-group" style="margin-bottom: 15px"> 
            <button class="ts-tab-button mx-2" :class="{ active: active == 1 }" @click="handleTab(1)">APP/WEB</button>
            <button class="ts-tab-button mx-2" :class="{ active: active == 2 }" @click="handleTab(2)">제품개발</button>
          </div>
        </div>
      </div>
    </div>

    <swiper
      :slidesPerView="1"
      :spaceBetween="0"
      :breakpoints="{
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1700: {
          slidesPerView: 6,
          spaceBetween: 20
        },
        2000: {
          slidesPerView: 7,
          spaceBetween: 20
        },
      }"
      :pagination="{type: 'progressbar'}"
      :modules="[Pagination]"
      class="works-swiper"
      @swiper="getSwiperRef"
    >
      <swiper-slide ref="$swiperItemDummy"></swiper-slide>

      <swiper-slide v-for="(item, index) in works" :key="index" class="works-item">
        <img :src="require(`@/assets/data/${item.image}`)" />

        <div class="overlay">
          <span class="title">HONDA KOREA Brand Website</span>
          <div style="margin-top: 18px;">
            <span class="tag">UI/UX</span>
            <span class="tag">Development</span>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </swiper>
  </div>
</template>

<script setup>
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/works_title.json';
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const active = ref(1);
const works = ref([
  {
    image: "works/works-01.png",
  },
  {
    image: "works/works-02.png",
  },
  {
    image: "works/works-03.png",
  },
  {
    image: "works/works-04.png",
  },
  {
    image: "works/works-05.png",
  },
  {
    image: "works/works-06.png",
  },
]);

const handleTab = (pos) => {
  active.value = pos;
  if (pos == 1) {
    // Nothings..
  } else {
    // Nothings..
  }
}

const $topGroup = ref();
const $swiper = ref();

const getSwiperRef = (instance) => {
  $swiper.value = instance.el; //wrapperEl

  // 모바일인 경우 1번 부터
  if (window.innerWidth <= 576)  {
    instance.slideTo(1, 0, null);
  }
}

const handleResize = () => {
  // 여백기준 
  var topRect = $topGroup.value.getBoundingClientRect();

  var itemRect = $swiper.value
    .getElementsByClassName("swiper-slide")[0]
    .getBoundingClientRect();

  
  var $wrapper = $swiper.value.getElementsByClassName("swiper-wrapper")[0];
  var space = itemRect.width - topRect.left;

  var $pagination = $swiper.value.getElementsByClassName("swiper-pagination")[0];
  
  // 모바일 사이즈 인 경우 
  if (window.innerWidth <= 576) {
    $wrapper.style.marginLeft = '0';
    return ;
  }

  if (space > 0) {
    $wrapper.style.marginLeft = `-${space}px`;
    $pagination.style.width = `${window.innerWidth - topRect.left}px`;
    $pagination.style.left = `${topRect.left + 20}px`;
  }
}

onBeforeMount(async () => {
  active.value = 1;
})

onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize();
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
})

</script>

<style lang="scss" scoped>
#works-main {
  background-image: url("@/assets/image/works-bg.png");

  @media (max-width: 576px) {
    padding: 0px 20px;  
  }
}

.tab-group {
  display: flex;
  flex-direction: row;
}

.ts-tab-button {
  width: 160px;
  height: 50px;

  border: solid 1px #dcdcdc;
  background-color: #fff;
  border-radius: 25px;

  font-family: 'NanumBarunGothic';
  font-size: 16px;
  font-weight: bold;
  color: rgba(65, 65, 65, 0.53);

  &.active {
    color: #7465b8;
  }
}

.swiper {
  max-width: 100% !important;

  @media (min-width: 576px) {
    height: 325px;  
  }
  
}

.works-item {
  text-align: center;
  font-size: 18px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 576px) {
    height: 300px;
    background: #fff;

    border-radius: 12px;
    overflow: hidden;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover img {
      opacity: 0.3;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      color: white;
      padding: 28px;

      .title {
        font-size: 22px;
        text-align: start;
      }

      .tag {
        height: 30px;
        padding: 4px 12px;
        font-size: 14px;
        border-radius: 25px;
        border: solid 1px #fff;
        margin-right: 4px;
      }
    }
    &:hover .overlay {
      transition: .5s ease;
      opacity: 1;
      background-color: rgba(62, 61, 70, 0.8);
    }  
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    color: red;

    img {
      border-radius: 12px;
    }

    .overlay {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0px;
      color: #8C8C8C;

      .title {
        font-size: 22px;
        text-align: start;
      }

      .tag {
        height: 30px;
        padding: 4px 12px;
        font-size: 14px;
        border: 1px solid #A6A6A6;
        border-radius: 25px;
        margin-right: 4px;
      }
    }
  }
}
</style>

<style lang="scss">
.works-swiper {
  .swiper-pagination-progressbar-fill {
    border: 2px solid black;
    border-radius: 5px;
  }

  .swiper-pagination-progressbar {
    background-color: #CECECE;
    border-radius: 5px;

    position: absolute;
    bottom: 0;
    top: unset;
  }

  @media (max-width: 576px) {
    .swiper-pagination-progressbar {
      display: none !important;
    }
  }
}
</style>