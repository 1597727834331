<template>
  <div class="w-100 h-100">
    <TheHeader id="ts-header" class="w-100" />

    <div id="home-bg">
      <Swiper 
        :pagination="pagination" 
        :modules="modules" 
        class="home-swiper"
      >
        <SwiperSlide>
          <HomeFirst />
        </SwiperSlide>
        <SwiperSlide>
          <HomeSecond />
        </SwiperSlide>
        <SwiperSlide>
          <HomeThird />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import TheHeader from '@/layouts/TheHeader.vue'
import HomeFirst from '@/views/home/HomeFirst.vue'
import HomeSecond from '@/views/home/HomeSecond.vue'
import HomeThird from '@/views/home/HomeThird.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { onMounted } from 'vue'

const modules = [Pagination]

const pagination = { clickable: true, }

onMounted(() => {
  // 깊어져서 그래서 Store 를 써서 접근하도록 하는구나 .. OK 내일은 이거다 
  // props.fullpage.moveTo(2);
});

</script>

<style lang="scss" scoped>
#ts-header {
  position: absolute;
  top: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

#home-bg {
  /* The image used */
  background-image: url('@/assets/image/home-bg.png');

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 576px) {
  #ts-header {
    top: 0px;
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}
</style>

<style lang="scss">
.home-swiper {
  width: 100%;
  height: 100%;

  .swiper-pagination {
    margin-bottom: 16px;
  }

  .swiper-pagination-bullet {
    width: 120px;
    height: 4px;
    background: #b7b7b7;
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    width: 120px;
    transition: width 0.5s;
    border-radius: 5px;
    background: #6a5bfb;
    border: 1px solid transparent;
  }
}

@media (max-width: 576px) {
  .swiper-pagination-bullet {
    width: 50px;
  }
}
</style>
