<template>
  <div class="d-flex flex-column align-items-center justify-content-center h-100 text-white">
    <div>
      <img src="@/assets/image/temps-texture.svg" />
    </div>

    <div class="mt-3">
      <img src="@/assets/image/temps-texture-sub.svg" />
    </div>

    <p id="ts-message" class="mt-4" style="white-space: pre-wrap;">
      {{ message }}
    </p>
    <div style="height: 100px;" ></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const message = ref("템스는 각 분야의 전문가들로 이루어진 전문 개발 그룹으로, 다양한 기술과 전문성에 기반한 아이디어와 가치를 구현합니다.\nTEMPS라는 이름은 프랑스어로 '시간'을 의미하며, 우리는 다양한 기술의 융합을 통해 창의적이고 적극적으로 설정된 목표와 개발 납기를 철저히 지킴으로써 고객에게 선택과 집중할 수 있는 시간을 만들어 드립니다.") 


onMounted(() => {
  
})

</script>

<style lang="scss" scoped>

#ts-message {
  width: 550px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
}

@media (max-width: 576px) {
  #ts-message {
    width: 80%;
  }
}
</style>