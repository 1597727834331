<template>
  <div class="container-fluid text-white h-100" style="overflow: hidden;">
    <div 
      id="ts-content" 
      class="row h-100 row-cols-1 row-cols-sm-2">
      <div id="section1" class="col">
        <div id="text-content">
          <p id="title" class="fs-1 fw-bold">We will guide your<br />Service.</p>
          <p id="message" class="fs-6">
            우리는 당신의 시간과 돈의 가치를 잘 알고 있습니다. <br />
            전문 UI/UX 디자이너와 Full-stack 개발자들이 기획부터 디자인, 개발, 배포까지 책임지고 완료합니다. 다수의 앱을 성공적으로 배포한 경험을 바탕으로 고객의 만족을 실현하는 기업이 되겠습니다.
          </p>

          <button class="ts-button my-3 d-sm-block d-none">
            <span>프로젝트 상담</span>
            <img class="mx-2" src="@/assets/icon/arrow-next.svg" />
          </button>  
        </div>
      </div>

      <div id="section2" class="col">
        <img src="@/assets/data/product_01.png" />
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
#ts-content {
  min-width: 1400px;

  @media (max-width: 576px) {
    min-width: 360px;
  }
}

#section1 {
  display: flex;
  justify-content: flex-end;
  align-self: center;

  @media (max-width: 576px) {
    align-self: flex-end;
  }
}

#section2 {
  overflow: hidden;
  display: flex;
  align-items: center;

  img {
    max-height: 100vh;
  }

  @media (max-width: 576px) {
    display: flex;
    align-items: center;

    img {
      max-width: 130%;
      height: auto;
    }
  }
}

#title {
  @media (max-width: 576px) {
    text-align: center;
    font-size: 22px !important;
    align-self: center;
  }
}

#message {
  max-width: 550px;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 576px) {
    max-width: none;
    font-size: 14px;
    text-align: center;
  }
}


.ts-button {
  width: 240px;
  height: 55px;
  border: 0px;
  border-radius: 35px;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ts-button span {
  font-size: 20px;
  font-weight: 500;
  background-image: linear-gradient(to left, #e291c4, #867ab9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>