<template>
  <div id="home-map" class="modal">
    <div class="modal-dialog modal-fullscreen text-white">
      <div class="modal-content">
        <img 
          id="close" 
          src="@/assets/icon/pop-close.svg" 
          style="align-self: end;"
          data-bs-dismiss="modal"
        >

        <div id="content-group" class="row row-cols-sm-1 row-cols-1">
          <div class="col">
            <p class="fs-1 fw-medium m-0">Localtion</p>
          </div>

          <div class="col container-fluid">
            <div class="row">
              <div class="col-sm-8 col-12 loc-item">
                <img src="@/assets/icon/address-small.svg">
                <span class="fs-7">용인시 기흥구 서천로201번길 31 이너매스허브시티 4층(425/426/415호)</span>
              </div>
              <div class="col-sm-4 col-12 loc-item">
                <img src="@/assets/icon/call-small.svg">
                <span class="fs-7">070-8620-1965</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <img class="my-3" src="@/assets/test.png"> -->
        <div id="map" class="my-3" ref="$map" style="width: 100%; height: 400px;"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/store/dialog";

const $map = ref(null);
const handleResize = (event) => {
  // console.log('check >> ', window.innerWidth, window.innerHeight);
}

onMounted(() => {
  var map = new window.naver.maps.Map('map', {
    center: new window.naver.maps.LatLng(37.2296032, 127.0726984),
    zoom: 17
  });

  var marker = new window.naver.maps.Marker({
    position: new window.naver.maps.LatLng(37.2296032, 127.0726984),
    map: map,
  })
  window.naver.maps.Event.addListener(marker, 'click', () => {
    // console.log('check!');
  });


  
  window.addEventListener('resize', handleResize);
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
})

// Event 
const show = ref(false);
const store = useDialogStore();
const { dialog } = storeToRefs(store);

// 네이버 지도가 반쪽만 나오는 이슈를 해결하기 위해서 Resize 이벤트 트리거 
watch(dialog, () => {
  show.value = true;
  window.dispatchEvent(new Event('resize'));
})

</script>

<style lang="scss" scoped>
.modal-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: transparent;
  width: 70%;

  display: flex;
  align-items: start;
  justify-content: center;

  img {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .modal-content {
    width: 100%;
  }

  #close {
    width: 24px;
    margin-top: 24px;
    margin-right: 16px;
  }

  #content-group {
    padding: 0 16px;
  }

  #map {
    height: 100% !important;
  }

  .loc-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
  }
}
</style>

<style lang="scss">
.modal-backdrop {
  // background: red;

  &.show {
    opacity: 0.75 !important;
  }
}
</style>