<template>
  <div id="fullpage">
    <slot />
  </div>

  <div> 
    <p>counter: {{ store.counter }}</p>
    <p>counter: {{ store.doubleCounter }}</p>
  </div>
</template>


<script setup>
import $ from 'jquery';
import '@/assets/js/fullpage.min.css';
import '@/assets/js/fullpage.extensions.min';
import { onBeforeUnmount, onMounted } from 'vue';
import { useNavStore } from '@/store/nav';

const store = useNavStore();

onMounted(() => {
  if($('html').hasClass('fp-enabled') == false){
    init();
  }
})

const init = () => {
  $('#fullpage').fullpage({
    onLeave: function(index, nextIndex, direction) {
      // console.log("onLeave--" + "index: " + index + " nextIndex: " + nextIndex + " direction: " +  direction);
      store.changePosition(nextIndex);
    },
    afterLoad: function(anchorLink, index){
      // console.log("after Load  " + index);
      // console.log('===============');
      // console.log("afterLoad--" + "anchorLink: " + anchorLink + " index: " + index );
      // store.changePosition(index);
    }
  });

  store.$instance = $.fn.fullpage;
}

const moveTo = (position) => {
  $.fn.fullpage.moveTo(position);
}
</script>


<style lang="scss" scoped>
</style>