<template>
  <div>
    <TheSidebar />
    
    <TheFullPage>
      <div class="section">
        <home-page />
      </div>

      <div class="section">
        <company-page />
      </div>

      <div class="section">
        <WorksPage />
      </div>

      <div class="section">
        <process-page />
      </div>

      <div class="section">
        <partner-page />
      </div>

      <div class="section">
        <PeoplePage />
      </div>

      <div class="section">
        <culture-page />
      </div>

      <div class="section fp-auto-height">
        <TheFooter />
      </div>
    </TheFullPage>

    <HomeMap />
  </div>
</template>

<script setup>
import TheFullPage from './layouts/TheFullPage.vue'
import TheSidebar from './layouts/TheSidebar.vue'
import HomePage from './views/home/HomePage.vue'
import CompanyPage from './views/company/CompanyPage.vue'
import ProcessPage from './views/process/ProcessPage.vue'
import WorksPage from './views/works/WorksPage.vue'
import PartnerPage from './views/partner/PartnerPage.vue'
import CulturePage from './views/culture/CulturePage.vue'
import TheFooter from './layouts/TheFooter.vue'
import PeoplePage from './views/people/PeoplePage.vue'
import HomeMap from './views/home/HomeMap.vue'
import { onMounted } from 'vue'

onMounted(() => {
  document.title = '(주)템스';
})

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
// @import url(http://www.openhiun.com/hangul/nanumbarungothic.css); 

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans KR', 'NanumSquare', sans-serif !important;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img { 
  -webkit-user-drag: none; 
  -khtml-user-drag: none; 
  -moz-user-drag: none; 
  -o-user-drag: none; 
  user-drag: none; 
}

.ts-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ts-page-content {
  min-width: 1200px;

  @media (max-width: 576px) {
    min-width: 360px;
  }
}

.ts-page-ci {
  display: block;
  position: absolute;
  top: 25px;
  left: 40px;

  @media (max-width: 576px) {
    display: none !important;
  }
}

.ts-title {
  margin: 0 !important;
  /* Lottie 에 의해 발생한 여백삭제 */
  margin-top: -100px !important;

  @media (max-width: 576px) {
    height: 100px !important;
    width: 200px !important;
    margin-top: 0 !important;
  }
}

#ts-sidebar {
  @media (max-width: 576px) {
    display: none !important;
  }
}

</style>
