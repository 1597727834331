<template>
  <div id="company-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div class="container ts-page-content">
      <lottie class="ts-title" :options="titleOptions" :width="400" :height="200"/>

      <div class="row row-cols-1 row-cols-sm-2">
        <div id="section1" class="col d-flex flex-column order-sm-1 order-2">
          <p id="message">{{ message }}</p>

          <div id="sub-message" class="d-flex text-center my-4">
            <div class="item">APP 및<br />서비스 개발</div>
            <div class="line"></div>
            <div class="item">제품 개발</div>
            <div class="line"></div>
            <div class="item">금형 및<br />양산</div>
          </div>

          <ts-button style="margin-top: 30px;"></ts-button>
        </div>

        <div id="section2" class="col d-flex order-sm-2 order-1">
          <img src="@/assets/image/company-right.png" style="align-self: center;"/>
        </div>
      </div>
    </div>
  </div>
</template> 

<script setup>
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/company_title.json';
import TsButton from '@/components/TsButton.vue';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const message = "TEMPS는 10년 이상 경력의 개발자가 모여 창업한 회사로 다양한 제품 및 소프트웨어를 설계/개발하며 축적된 기술 노하우를 바탕으로 혁신적인 제품 개발을 위한 최적의 아이디어를 제공하는 전문 개발 회사입니다.\n다년간 축적된 제품 개발 경험을 바탕으로 제품에 대한 기획 단계부터, 기구/하드웨어설계 및 소프트웨어 개발, 시제품제작, 금형, 사출 양산에 이르기까지 제품개발 전  과정에 대한 전문성을 바탕으로 제품에 대한 다양한 서비스를 제공 함으로써, 고객의 대내외 경쟁력 확보가 가능하도록 최선을 다하고 있습니다." 
</script>

<style lang="scss" scoped>
#company-main {
  background-image: url("@/assets/image/company-bg.png");
}

#section1 {
  justify-content: center;

  @media (max-width: 576px) {
    margin: 20px 0px;
    padding: 0 20px;
  }
}

#section2 {
  justify-content: center;

  @media (max-width: 576px) {
    img {
      height: 90vw;
    }
  }
}

#message {
  white-space: pre-wrap;
  color: #6A6A6A;
  font-size: 16px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
}

#sub-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;

  .item {
    flex: 1;
    font-size: 18px;
    color: #868686;
  }

  .line {
    width: 1px;
    height: 55px;
    background-color: #616161;
  }

  @media (max-width: 576px) {
    display: none !important;
  }
}
</style>