<template>
  <div id="process-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div id="process-content" class="container ts-page-content">
      <div id="top-group" class="row row-cols-1 row-cols-sm-2" style="margin-bottom: 20px">
        <div class="col">
          <lottie class="ts-title" :options="titleOptions" :width="400" :height="200" />
        </div>

        <div class="col">
          <div>
            <button class="ts-tab-button mx-2" :class="{ active: active == 1 }" @click="handleTab(1)">APP/WEB</button>
            <button class="ts-tab-button mx-2" :class="{ active: active == 2 }" @click="handleTab(2)">제품개발</button>
          </div>
        </div>
      </div>

      <div id="bottom-group" class="row row-cols-1 row-cols-sm-2">
        <!-- <div class="col" style="background-color: red; height: 100px;"></div>
        <div class="col" style="background-color: blue; height: 100px;"></div> -->
        <div class="col">
          <img :src="require(`@/assets/image/${guideImage}`)" style="max-width:100%; flex-shrink : 0;"/>
          <div class="d-none d-sm-block" style="height: 150px;"></div>
        </div>

        <div class="col d-flex flex-column align-items-center justify-content-center">
          <div id="process-desc-item">
            <p>{{ processLabel }}</p>
            <span>{{ proceeMsg }}</span>
          </div>
          <div style="height: 150px;"></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script setup>
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/process_title.json';
import { onBeforeMount, ref } from 'vue';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const active = ref(1);
const guideImage = ref("");
const processLabel = ref("");
const proceeMsg = ref("");

const handleTab = (pos) => {
  active.value = pos;
  if (pos == 1) {
    guideImage.value = "process-sw.png";
    processLabel.value = "개발 착수";
    proceeMsg.value = "의뢰받은 프로젝트의 아이디어 또는 서비스를 구체화하는 단계로 프로젝트의 성격 분석과 경쟁 서비스 분석, 타겟 분석 등을 진행하는 사전 기획 단계이다. 고객과의 커뮤니케이션을 통해 프로젝트의 크기를 산정하고 그에 따른 투입 인력  및 일정을 산출한다.";
  } else {
    guideImage.value = "process-hw.png";
    processLabel.value = "제품 기획";
    proceeMsg.value = "의뢰받은 프로젝트의 아이디어 또는 서비스를 구체화하는 단계로 프로젝트의 성격 분석과 경쟁 서비스 분석, 타겟 분석 등을 진행하는 사전 기획 단계이다. 고객과의 커뮤니케이션을 통해 프로젝트의 크기를 산정하고 그에 따른 투입 인력  및 일정을 산출한다."
  }
}

onBeforeMount(async () => {
  handleTab(1);
});

</script>

<style lang="scss" scoped>
#process-main {
  background-image: url("@/assets/image/process-bg.png");
}

#top-group {
  position: relative;

  div:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: end;
  }
}

#bottom-group {
  // display: flex;
  @media (max-width: 576px) {
    > div:last-child {
      margin-top: 15px;
    }
  }

  @media (min-width: 576px) {
    > div:first-child {
      flex: 7;
      text-align: center;
    }

    > div:last-child {
      flex: 5;
      
    }  
  }
}


.ts-tab-button {
  width: 160px;
  height: 50px;
  border-radius: 25px;
  border: solid 1px #dcdcdc;
  background-color: #fff;

  font-family: 'NanumBarunGothic';
  font-size: 16px;
  font-weight: bold;
  color: rgba(65, 65, 65, 0.53);

  &.active {
    color: #7465b8;
  }

  @media (max-width: 576px) {
    width: 130px;
    height: 40px;
    font-size: 14px;
  }
}

#process-desc-item {
  p {
    font-size: 24px;
    color: #252525;
  }

  span {
    font-size: 16px;
    color: #6A6A6A;
  }
}
</style>