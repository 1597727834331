<template>
  <div id="partner-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div id="partner-content" class="container ts-page-content">
      <lottie class="ts-title" :options="titleOptions" :width="500" :height="200" />

      <div class="row row-cols-2 row-cols-sm-4 g-2">
        <div v-for="(item, index) in partners" :key="index" class="col">
          <div class="partner-item">
            <img :src="require(`@/assets/data/${item.icon}`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/partner_title.json';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const partners = ref([
  {icon: 'partner-ci/partner-01.png'},
  {icon: 'partner-ci/partner-02.png'},
  {icon: 'partner-ci/partner-03.png'},
  {icon: 'partner-ci/partner-04.png'},
  {icon: 'partner-ci/partner-05.png'},
  {icon: 'partner-ci/partner-06.png'},
  {icon: 'partner-ci/partner-07.png'},
  {icon: 'partner-ci/partner-08.png'},
  {icon: 'partner-ci/partner-09.png'},
  {icon: 'partner-ci/partner-10.png'},
])


</script>

<style lang="scss" scoped>

#partner-main {
  background-image: url("@/assets/image/partner-bg.png");

  @media (max-width: 576px) {
    justify-content: start !important;
  }
}

#partner-content {
  text-align: center;
  padding: 20px;
}

.partner-item {
  background-color: white;
  border: 1px solid #E5E8EA;
  border-radius: 10px;

  @media (max-width: 576px) {
    img {
      max-width: 90%;
    }
  }
}

</style>