<template>
  <div id="people-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div id="people-content" class="container ts-page-content">
      <lottie class="ts-title" :options="titleOptions" :width="500" :height="200" />

      <div class="row row-cols-2 row-cols-sm-6 g-4">
        <div 
          class="people-item" 
          v-for="(item, index) in peoples" 
          :key="index"
        >
          <!-- <img class="rounded-circle" :src="item.image" /> -->
          <img class="rounded-circle" src="@/assets/image/user-default.jpg" />

          <p class="m-0">{{ item.name }}</p>
          <span>{{ item.rule }}</span>
        </div>

        <div class="people-item enroll-item">
          <img src="@/assets/image/user-enroll.png" />
          <p class="m-0">입사지원 하기!!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/team_title.json';
import { ref } from 'vue';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const peoples = ref([
  {
    name: '정재훈',
    rule: 'CEO', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: '이용우',
    rule: '소프트웨어 엔지니어', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: '임경환',
    rule: '기구설계 엔지니어', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: '문태수',
    rule: '제품 디자이너', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: 'Zeroport',
    rule: '기획 & UI/UX Designer', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: '윤관식',
    rule: 'Unity Developer', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
  {
    name: 'Hyeona',
    rule: 'QA', 
    image: 'https://mdbcdn.b-cdn.net/img/new/avatars/9.webp'
  },
])
</script>

<style lang="scss" scoped>
#people-main {
  background-image: url("@/assets/image/people-bg.png");

  @media (max-width: 576px) {
    justify-content: start;  
  }
  
}

#people-content {
  > div.row {
    min-height: 550px;
  }
  
}

.people-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    color: #7D69D0;
  }

  span {
    margin: 0 auto;
    font-size: 14px;
    color: #878787;
  }

  @media (max-width: 576px) {
    img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>