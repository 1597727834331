<template>
  <div id="culture-main" class="ts-page-container">
    <img class="ts-page-ci" src="@/assets/image/temps-logo-fill.svg" />

    <div class="container text-center ts-page-content">
      <lottie class="ts-title" :options="titleOptions" :width="500" :height="200" />

      <div class="row row-cols-sm-4 row-cols-2 g-2">
        <div v-for="(item, index) in clutures" :key="index" class="col">
          <div id="culture-item">
            <p class="m-0">{{ item.title }}</p>
            <span>{{ item.content }}</span>

            <div class="space"></div>

            <div class="img-wrap">
              <img :src="require(`@/assets/data/${item.icon}`)"/>
            </div>

            <div class="ts-line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Lottie from 'vue-lottie';
import * as animationData from '@/assets/animation/culture_title.json';

const titleOptions = {
  animationData: animationData,
  loop: false,
}

const clutures = ref([
  {
    title: '입사 축하 선물',
    content: '첫 출근하는 너를 위해 준비했어~',
    icon: 'culture-icon/culture-01.svg',
  },
  {
    title: '유연한 근무 시간',
    content: 'AM 8~11시 출근, PM 5~8시 퇴근',
    icon: 'culture-icon/culture-02.svg',
  },
  {
    title: '식사 제공',
    content: '점심 및 저녁 식사 제공',
    icon: 'culture-icon/culture-03.svg',
  },
  {
    title: '스낵바',
    content: '무제한 간식 제공~!',
    icon: 'culture-icon/culture-04.svg',
  },
  {
    title: '명절 선물 및 상여금',
    content: '양손은 무겁게! 지갑은 두껍게!',
    icon: 'culture-icon/culture-05.svg',
  },
  {
    title: '교육비 지원',
    content: '자기개발을 위한 교육비 지원',
    icon: 'culture-icon/culture-06.svg',
  },
  {
    title: '도서 구매 지원',
    content: '업무를 위한 도서 구매! 무제한 지원!',
    icon: 'culture-icon/culture-07.svg',
  },
  {
    title: '인재 추천 보상',
    content: '추천 인재 합격시 보상 제도!',
    icon: 'culture-icon/culture-08.svg',
  },
  {
    title: '자유로운 연차',
    content: '우리 쉴때는 눈치보지 말아요~',
    icon: 'culture-icon/culture-09.svg',
  },
  {
    title: '프로젝트 인센티브제',
    content: '프로젝트 완료시 인센티브 지급',
    icon: 'culture-icon/culture-10.svg',
  },
]);



</script>

<style lang="scss" scoped>
#culture-main {
  background-image: url("@/assets/image/culture-bg.png");

  @media (max-width: 576px) {
    justify-content: start;
  }
}

#culture-item {
  position: relative;
  background-color: #EEF0F1;
  height: 170px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 23px 30px;

  border-radius: 10px;

  p {
    font-size: 18px;
    font-weight: 700;
    color: #212121;
  }

  span {
    white-space: nowrap;
    font-size: 14px;
    color: #949494;
  }

  .space {
    flex: 1;
  }

  img {
    align-self: start;
  }

  @media (max-width: 576px) {
    height: 140px;
    padding: 10px 15px;
    
    p {
      font-size: 14px;
      font-weight: normal;
    }

    span {
      font-size: 12px;
    }

    img {
    }
  }
}

.ts-line {
  background: #5A5A5A;
  height: 1px;
  margin-top: -1px;
  width: 80%;
}
</style>