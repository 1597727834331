import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { BootstrapVue3 } from 'bootstrap-vue-3';
import { createPinia } from 'pinia';

const app = createApp(App);
app.use(BootstrapVue3);
app.use(createPinia());
app.mount("#app");
