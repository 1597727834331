import { defineStore } from "pinia";

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    dialog: '',
  }),
  actions: {
    open(name) {
      console.log('actions!', name);
      this.dialog = name;
    }
  }
})