<template>
  <nav class="navbar navbar-expand-sm bg-transparent">
    <div id="navbar-main" class="container-fluid">
      <a class="navbar-brand mx-3">
        <img id="logo" src="@/assets/image/temps-logo.svg">
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu"
        aria-controls="navbarMenu" aria-expanded="false">
        <span class="navbar-toggler-icon">
        </span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarMenu">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" @click="handleClicked(1)">COMPANY</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="handleClicked(2)">WORKS</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" @click="handleClicked(3)">PROCESS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="handleClicked(4)">PARTNER</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="handleClicked(5)">CULTURE</a>
          </li>
        </ul>
      </div>

      <img
        id="location" 
        class="mx-sm-4 v-b-modal.my-modal" 
        src="@/assets/icon/location.svg"
        data-bs-toggle="modal" 
        data-bs-target="#home-map"
        @click="onLocaltionClicked" 
      >
    </div>
  </nav>
</template>

<script setup>
import { useNavStore } from "@/store/nav";
import { useDialogStore } from "@/store/dialog";
// import { storeToRefs } from "pinia";
// import { onMounted, ref } from "vue";

const store = useNavStore();

const handleClicked = (pos) => {
  store.moveTo(pos + 1);
}


// Location
const dialogStore = useDialogStore();
const onLocaltionClicked = () => {
  dialogStore.open('localtion');
}

</script>

<style lang="scss" scoped>
.nav-link {
  color: white;
}

.nav-link.active {
  color: white !important;
}

#location {
  display: inline;
}

.navbar-nav {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0px 30px;
}

@media (max-width: 576px) {
  #logo {
    width: 100px;
  }

  .navbar-brand {
    flex: 1;
  }

  #navbar-main {
    justify-content: unset;
  }

  #location {
    width: 24px;
    padding: 4px;
  }

  .navbar-toggler {
    border: 0px;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .navbar-toggler-icon {
    background-image: url('@/assets/icon/menu.svg');
    border: 0px !important;
  }
}
</style>