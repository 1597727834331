import { defineStore } from "pinia";

export const useNavStore = defineStore('nav', {
  state: () => ({
    position: 1,
    $instance: null,
  }),
  getters: {

  },
  actions: {
    changePosition(pos) {
      this.position = pos;
    },
    moveTo(pos) {
      this.$instance.moveTo(pos);
    } 
  }
});