<template>
  <div id="ts-sidebar" ref="$sidebar" :class="{ hide: isHidden }" :style="{}">
    <div class="ts-sidebar-content">
      <div class="sidebar-item" 
        :class="{active: index == activeIndex}"
        v-for="(item, index) in items" 
        :key="index" 
        @click="navItemClicked(index)"
      >
        <div class="content">
          <img :src="require(`@/assets/icon/sidebar/${item.icon}-${(index == activeIndex) ? 'on' : 'off'}.png`)">
          <span v-show="index == activeIndex">{{ item.name }}</span>
        </div>
        <hr v-show="index < items.length">
      </div>
    </div>

    <div id="ts-top" @click="navItemClicked(-1)">
      <img src="@/assets/icon/arrow-up.svg">
      <span>TOP</span>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useNavStore } from "@/store/nav";
import { storeToRefs } from "pinia";

const items = ref([
  {
    name: 'company',
    icon: 'company'
  },
  {
    name: 'works',
    icon: 'works'
  },
  {
    name: 'process',
    icon: 'process'
  },
  {
    name: 'partner',
    icon: 'partner'
  },
  {
    name: 'team',
    icon: 'team'
  },
  {
    name: 'culture',
    icon: 'culture'
  },
])

const $sidebar = ref(null);
const activeIndex = ref(0);

const store = useNavStore();
const { position } = storeToRefs(store);
const isHidden = ref(true);

// Fullpage 위치 변경 시 
watch(position, () => {
  handleWatch();
})

// 위치변경에 따른 사이드바 출력 여부 결정 
const handleWatch = () => {
  // index: -1, company: -1
  activeIndex.value = (position.value - 2);

  //  || (position.value == 3)
  if ((position.value == 1)) {
    isHidden.value = true;
    // 완전히 감추기 위해 위치를 변경함. -> TheHeader 의 위치버튼이 겹치는 문제 보완 
    $sidebar.value.style = "transform: translate(0%, 100%);"
  } else {
    isHidden.value = false;
    $sidebar.value.style = ""
  }
}

const navItemClicked = (index) => {
  store.moveTo(index + 2);
}

onMounted(() => {
  // 시작 시 기본값으로 설정 
  handleWatch();
})



</script>

<style lang="scss" scoped>
#ts-sidebar {
  display: flex;
  width: 90px;
  height: 100vh;
  position: sticky;
  float: right;
  z-index: 100;
  opacity: 1;
  transition: .5s ease;

  &.hide {
    opacity: 0;
    transition: .2s ease;
  }
  
  .ts-sidebar-content {
    align-self: center;
    width: 100%;
    border-radius: 10px 0 0 10px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

#ts-top {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 25px;
  right: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}

.sidebar-item {
  position: relative;
  width: 90px;
  height: 90px;
  text-align: center;

  span {
    font-size: 13px;
    font-weight: 600;
    color: #A5D2FE;
    text-transform: uppercase;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  hr {
    color: white;
    width: 90%;
    margin: auto;
    // position: absolute;
    // bottom: 0;
    // color: rgba(217, 224, 225, 0.25);
  }
}


</style>